@import url('https://fonts.googleapis.com/css2?family=Urbanist&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
* { padding: 0px; margin: 0px; }
 body, html { overflow-x: hidden !important;}
li{list-style: none;}
 a { text-decoration: none; }
:root {
  --black: #666;
  --yellow:#CBA553;
  --maroon: #5D1010;
  --white: #fff;
  --font-family: 'Urbanist', sans-serif;;
}
p,h1,h2,h3,h4,h5,h6{font-family: var(--font-family);}
.header-left img {
    width: 390px;
}
.lazyload-wrapper {
    display: unset;
}
header {
    position: relative;
}
.header-right-bottom nav {
    padding-right:25px;
}
.header {
    display: flex;
    flex-direction: row;
    padding: 0px 50px;
    justify-content: center;
    align-items: center;
}

.header-left {
    width: 30%;
}

.header-right {
    width: 100%;
}
.header-right-top {
    display: flex;
    justify-content: end;
    align-items: center;
}

.header-right-top ul {
    padding-left: 0px;
    display: flex;
    padding-right: 40px;
}
.header-right-top ul li:hover:before {width: 100%;}
.header-right-top ul li:hover img {
    filter: sepia(10);
}
.header-right-top ul li:before {
    position: absolute;
    content: ' ';
    width: 0;
    height: 2px;
    background: var(--yellow);
    top: 0;
    transition: all 0.3s ease-in;
}
.header-right-top ul li {
    padding: 10px;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
}
header:after {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 3px;
    bottom: 0;
    background: #CBA553;
    z-index: -1;
}
.nav-link {
    color: var(--black);
    font-size: 18px;
    line-height: 30px;
    text-transform: capitalize;
    font-weight: 500;
}
a.nav-link.active {
    color: var(--yellow);
}
.navbar-nav li {
    padding: 10px 4px;
}
.header-right-bottom {
    display: flex;
    justify-content: end;
    align-items: center;
}
.navbar .nav-item:hover .nav-link , .navbar .nav-link:focus{
    color: var(--yellow) !important;
}
a.mainbtn {
    background: var(--maroon);
    color: var(--white);
    border-radius: 10px;
    display: flex;
    padding: 5px 0px 5px 20px;
    align-items: center;
    margin-right: 25px;
}
a.mainbtn span {
    position: relative;
    width: 50px;
    height: 50px;
    background: var(--white);
    top: 0;
    right: -30px;
    padding: 12px 10px;
    border-radius: 0px 10px 10px 0px;
    border: 1px solid var(--maroon);
    z-index: 99;
    margin-top: -9px;
    margin-bottom: -8px;
}

a.mainbtn span img {display: block;}

a.mainbtn span:before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 0px;
    bottom: 0;
    left: -10px;
    display: block;
    width: 38px;
    background: #fff;
    transform: skew(-18deg);
    border-left: 1px solid var(--maroon);
    border-bottom: 1px solid var(--maroon);
    height: 49px;
}
a.mainbtnw {
    position: relative;
    background: #fff;
    padding: 8px 62px 8px 14px;
    color: var(--maroon);
    border-radius: 10px 10px 10px 10px;
    border: 1px solid var(--maroon);
    transition: all 0.5s linear;
}

a.mainbtnw span {
    background: var(--maroon);
    position: relative;
    z-index: 99;
    color: #fff;
    left: 40px;
    transition: all 0.5s linear;
}
a.mainbtnw:after {
    position: absolute;
    content: ' ';
    background: var(--maroon);
    width: 25%;
    height: 100%;
    right: 0px;
    top: 0;
    transform: scale(1.2);
    border-radius: 0px 10px 10px 0px;
    box-shadow: 2px 0px 2px 1px #00000033;
    transition: all 0.5s linear;
}

a.mainbtnw:before {
    position: absolute;
    content: ' ';
    background: var(--maroon);
    width: 28%;
    height: 100%;
    right: 8px;
    top: 0px;
    transform: scale(1.2) skew(-26deg);
    border-radius: 0px 10px 10px 0px;
    box-shadow: -3px 0px 3px 1px #00000033;
    transition: all 0.5s linear;
}
a.mainbtnw:hover {
    background: var(--maroon);
    color: var(--white);
}

a.mainbtnw:hover:before {
    background: var(--white);
}

a.mainbtnw:hover:after {
    background: var(--white);
}

a.mainbtnw:hover span {
    background: var(--white);
    color: var(--maroon);
}
a.mainbtn:hover {
    color: var(--yellow);
}
 .erp_links { left: 0px; position: fixed; bottom: 90px; z-index: 99; }
 .popup .carousel-control-prev-icon { background: url('https://webapi.entab.info/api/image/LFIS/public/Images/lefticn.png') no-repeat !important; }
 .popup .carousel-control-next-icon { background: url('https://webapi.entab.info/api/image/LFIS/public/Images/righticn.png') no-repeat !important; }
 .popup .carousel-control-next, .popup .carousel-control-prev { width: auto; }
 .popup .carousel-control-next { right: -15px; }
 .popup .modal-dialog { min-width: 800px; transform: translateY(-100px); }
 .carousel-item .modal-header { background: #0a4685; padding: 13px 0 0 }
 .popup .modal-dialog img { width: 100%; object-fit: contain; }
 .carousel-item .modal-body h1 { padding-bottom: 0; text-align: center; font-size: 17px; color: #343131; overflow: hidden; font-weight: 600; text-transform: capitalize; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; margin-bottom: 10px; line-height: 28px; }
 .carousel-item .modal-dialog .modal-header .btn-close { color: #fff; padding-right: 31px }
 .carousel-item .modalcard .card-body { padding: 10px }
 .carousel-item .modalcard .card-body p { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; line-height: 35px; text-align: center; padding-top: 10px; line-height: 26px; }
 .carousel-item .modalcard { border: none }
 .carousel-item .modal-body { padding: 0px 2rem; }
 .popup .modal-content { padding: 0 0 10px }
 .carousel-item button.btn-close:focus { box-shadow: none }
 .carousel-item a.btn.btn-primary.btn-sm {background: var(--maroon);border: #205b0e;margin: 0 10px}
 .carousel-item a.btn.btn-primary.btn-sm:focus { box-shadow: none; border: 1px solid #182b3c; }
 .carousel-item button.btn-close {position: absolute;z-index: 999;background-color: var(--maroon);right: 0;}
 .carousel-item .btn-close { background: url('https://webapi.entab.info/api/image/LFIS/public/Images/closeicn.png') no-repeat; opacity: 1; padding: 10px; background-position: center; border-radius: 0; }
 .modal-bottom { display: flex; flex-direction: row; justify-content: center; align-items: center; margin-top: 30px; }
 .erp_links img { width: 70px; }
 span.bounce1 img { width: 22px; }
 .OnlineR a { color: #fff; }
 a.parantlogo { padding: 2px 10px; }
 .OnlineR { position: fixed; right: 0; z-index: 99; text-align: center; color: #fff; bottom: 170px; font-family: Open Sans, sans-serif; }
 @keyframes bounce1 { 0% { background-color: var(--maroon); }
 33% { background-color: var(--yellow); }
 66% { background-color: var(--maroon); }
 100% { background-color: var(--yellow); }
 }
 .bounce1 { border-radius: 25px 0px 0px 25px; color: #fff; font-size: 14px; font-weight: 700; -webkit-animation: colorchange 20s infinite alternate; z-index: 99; text-align: center; animation: bounce1 1s infinite; -moz-animation: bounce1 1s infinite; -webkit-animation: bounce1 1s infinite; padding: 12px 12px; }
 .highlights {width: 100%;display: flex;flex-direction: row;justify-content: center;align-items: center;background: #CBA553;}
 .highlights p a {color: #fff;line-height: 30px;font-size: 15px;}
.highlights p {
    margin-bottom: 0;
}
 .excellencediv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: var(--maroon);
    padding: 30px 10px;
    align-items: center;
    flex-wrap: wrap;
}

.excellencediv p {
    font-size: 30px;
    line-height: 40px;
    font-style: italic;
    color: #fff;
    text-transform: capitalize;
    margin-bottom: 0;
    padding-right: 30px;
}
.typing {
  display:inline-flex;
}

.typing span {
  word-break: break-all;
  height: 1.2em;
  width:0%;
  overflow: hidden;
  animation:t 2s linear infinite alternate;
}
.typing span:before {
  content:" ";
  display:inline-block;
}

@keyframes t{
  90%,100% {width:100%}
}
 .midsec {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.midsecbox {
    width: calc(20% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 10px 0px #00000033;
    background: var(--white);
    margin: 10px;
    padding: 30px 10px;
    border-radius: 3px;
    position: relative;
    z-index: 9;
    overflow: hidden;
    text-align: center;
}
 .midsection {
    width: 100%;
    padding: 130px 10px;
    background: linear-gradient(185deg, #5d1010a1, #5d1010a1),url('https://webapi.entab.info/api/image/LFIS/public/Images/midsection.jpg') no-repeat;
    background-size: cover;
}
 .midsecbox p {
    font-size: 18px;
    padding: 10px 0px;
    margin-bottom: 0;
    color: #2B0000;
    font-weight: bold;
    text-transform: capitalize;
}

.midsecboximg {
    margin: 10px 0px;
}
 
 .homenewsboxdesc {
    background: #f5f5f5;
    padding: 15px 0px;
    border: 1px solid #D6D6D6;
    min-height: 135px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.homenewsboxtitle {
    background: var(--maroon);
    z-index: 9;
    position: relative;
}

.homenewsboxtitle p {
    color: var(--white) !important;
    font-size: 18px !important;
    padding: 5px 5px;
    text-align: center;
    margin-bottom: 0;
    line-height: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.homenewsboxdesc p {
    font-size: 16px;
    line-height: 28px;
    color: var(--black);
    padding: 0px 10px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0px;
}
.homenewsboxbottom {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 0px 10px;
}
.homenewsboxbottom span i {
    color: var(--maroon);
}
.homenewsboximg {
    border: 1px solid #5D1010;
    position: relative;
    overflow: hidden;
}
.homenewsbox {
    margin: 0px 10px;
}
.midsectionbottom {
    margin-top: -100px;
}
.mainheading {
    font-size: 40px;
    text-align: center;
    position: relative;
    margin-bottom: 15px;
    padding: 15px 0px;
}
.mainheading:before {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('https://webapi.entab.info/api/image/LFIS/public/Images/headingbg.png') no-repeat center bottom;
    bottom: -15px;
    left: 0;
    content: ' ';
}
.homenews, .counters,.skillingsec,.homeabout {
    padding: 60px 0px 75px 0px;
}
.homenewsbox {
    margin: 30px 10px;
}
.homenewsboxbottom {
    font-size: 15px;
    line-height: 28px;
    /* font-style: italic; */
}
.homenewsbox:hover .homenewsboximg img {
    transform: scale(1.1);
}

.homenewsboximg img {
    transition: all 0.5s ease-in;
    height: 245px;
    object-fit: cover;
}


.homenewsboxbottom a {
    color: var(--maroon);
}
.counters {
    position: relative;
    text-align: center;
    background: #fdf6e7;
}

.counters:after {
    position: absolute;
    background: #cba553;
    width: 100px;
    height: 100%;
    top: 0;
    right: 0;
    content: ' ';
}

.counters:before {
    position: absolute;
    background: #cba553;
    width: 100px;
    height: 100%;
    top: 0;
    left: 0;
    content: ' ';
}

.counters .container-fluid ,.skillingsec .container-fluid ,.gallery .container-fluid{padding: 0px 120px;}

.counters h4 {
    font-size: 80px;
    font-weight: bold;
    color: var(--maroon);
    font-family: 'Roboto Slab', serif;
    margin-bottom: 0;
    line-height: 120px;
}
.counting {
    display: flex;
    justify-content: center;
}

.counters span {
    font-size: 80px;
    font-weight: bold;
    color: var(--maroon);
    font-family: 'Roboto Slab', serif;
}
.counters  p {
    background: var(--yellow);
    color: #fff;
    font-size: 18px;
    line-height: 31px;
    margin: 0px 40px 0px 40px;
    padding: 0px 10px;
}
.counters .row> [class*="col-"] {
    border-right: 1px dashed var(--black);
}

.counters .row> [class*="col-"]:last-child {
    border: 0px solid #ccc;
}
.skillingquotes {
    text-align: center;
    font-size: 20px;
    margin-top: 35px;
    margin-bottom: 20px;
    color: var(--black);
    position: relative;
}
.skillingquotes img {
    margin-top: -16px;
}
.skillingcircle {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 60px 0px;
    justify-content: center;
    text-align: center;
}

.skillingcirclebox {
    width: calc(17% - 30px
);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
}

.skillingcircleboximg {
    width: 150px;
    border-radius: 100px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    padding: 10px;
    outline-offset: 10px;
    box-shadow: 0px 0px 10px 0px #00000040 inset;
    margin-bottom: 40px;
    position: relative;
}
.skillingcircleboximg:after {
    position: absolute;
    width: 100%;
    height: 100%;
    outline: 1px dashed #5D1010;
    content:  ' ';
    border-radius: 100px;
    outline-offset: 10px;
    animation: rotate  2s infinite linear;
}
.skillingcircleboximg img {
    padding: 25px;
}

.skillingcirclebox p {
    font-size: 17px;
    color: var(--maroon);
    font-weight: bold;
}

.infrastructuretitle {
    background: var(--maroon);
}

.infrastructuretitle p {
    color: var(--white) !important;
    font-size: 18px !important;
    padding: 5px 5px;
    text-align: center;
    margin-bottom: 0;
    line-height: 30px;
}

.infrastructureimg {
    position: relative;
}

.infrastructurereadmore {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px 10px;
    background: #FFD45199;
    opacity: 0;
    transition: all 0.4s ease-in;
}
.infrastructurereadmore a {
    color: #333333;
    font-weight: bold;
    font-size: 17px;
    line-height: 27px;
}

.infrastructuredesc {
    padding: 20px;
}

.infrastructuredesc p {
    font-size: 16px;
    line-height: 28px;
    color: var(--black);
    padding: 0px 10px;
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.infrastructure {
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 0 14px 4px rgb(0 0 0 / 8%);
    margin: 10px 6px;
    background: #fff;
    position: relative;
    z-index: 9;
}
.infrastructure:before {
    position: absolute;
    width: 100%;
    height: 0;
    content: ' ';
    bottom: 0;
    left: 0;
    transition: all 0.6s ease-in;
    background: #fdf6e7;
    z-index: -1;
}

.infrastructure:hover:before {

    height: 100%;
    bottom: 0;
}
.midsecbox:before {
    position: absolute;
    width: 0%;
    height: 100%;
    background: #fdf6e7;
    top: 0;
    left: 0;
    content: ' ';
    transform: translateX(-10px);
    transition: all 0.3s ease-in;
    z-index: -1;
}

.midsecbox:hover:before {
    width: 100%;
    transform: translateX(0px);
}

.midsecbox:hover img {
    transform: translateY(-10px);
}

.midsecbox img {
    transform: translateY(0px);
    transition: all 0.3s ease-in;
}
.gallery {
    width: 100%;
    padding: 60px 10px 70px 10px;
    background: #fdf6e7;
}

.galleryimg {
    box-shadow: 0px 7px 3px 0px #0000003d;
    border: 10px solid #fff;
    margin-bottom: 5px;
    margin-top: 40px;
}
.galleryimgbox img {
    min-height: 100%;
    object-fit: cover;
    background: #fff;
}
.gallerydesc {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: scale(1, 0) rotate(-45deg);
    transform-origin: bottom center;
    transition: transform 0.7s ease;
    background: #cba553d9;
    padding: 10px;
}
.galleryimg:hover .gallerydesc {
    transform: scale(1, 1);
}
.galleryimgbox {
    position: relative;
    overflow: hidden;
    height: 394px;
    min-height: 394px;
}

.gallerydesc p {
    position: absolute;
    bottom: 50px;
    width: 93%;
    margin-bottom: 0;
    color: var(--white);
    font-size: 20px;
    line-height: 30px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    left: 50px;
    padding-right: 15px;
}

.gallerydesc a {
    position: absolute;
    right: 10px;
}
.gallerydesc i {
    color: var(--white);
    font-size: 25px;
}
.gallerydesc:after {
    position: absolute;
    content: ' ';
     bottom: 30px;
    left: 0;
    width: 101%;
    height: 3px;
    background: linear-gradient(90deg, white 0%, rgba(255, 255, 255, 0.01) 99%, white 100%);
}
.gallerydesc:before {
    position: absolute;
    content: ' ';
     bottom: 0;
    left: 30px;
    width: 3px;
    height: 101%;
    background: linear-gradient(0deg, white 0%, rgba(255, 255, 255, 0) 100%);
}
.footerbottom {width: 100%;padding: 70px 0px 0px 0px;background: linear-gradient(185deg, #000000c7, #000000c7),url('https://webapi.entab.info/api/image/LFIS/public/Images/footerbg.jpg') no-repeat center center;background-size: 100% 100%;position: relative;overflow: hidden;z-index: 9;}

.footerbottom  h4 {
    font-size: 21px;
    color: var(--yellow);
    line-height: 35px;
    text-transform: capitalize;
    position: relative;
    margin-bottom: 10px;
}

.footerbottom h4:after {
    position: absolute;
    height: 2%;
    bottom: 0;
    left: 0;
    content: ' ';
    background: var(--yellow);
    width: 100%;
}
.copyright {
    background: #fff;
    text-align: center;
}
.copyright p 
{
        padding: 5px 0px;
    margin-bottom: 0;
    font-size: 14px !important;
    line-height: 28px !important;
    color: var(--black) !important;
}
.footerbottom .container {
    padding-bottom: 40px;
}
.copyright p a {
    color: var(--maroon);
    font-weight: 600;
}

.footerbottom p {
    color: var(--white);
    font-size: 15px;
    line-height: 26px;
}

.footerbottom ul {
    padding-left: 0;
    margin-bottom: 0;
}

.footerbottom ul li a {
    color: var(--white);
    font-size: 15px;
    line-height: 28px;
}
.footerbottom:before {width: 100%;content: ' ';background: url('https://webapi.entab.info/api/image/LFIS/public/Images/footerlogo.png') no-repeat left center;position: absolute;bottom: 14px;left: 0;height: 100%;z-index: -1;}


 #homeslider {
  position: relative;
  width: 100%;
  height: 76vh;
  overflow: hidden;
  background: #00000036;
}

#slides {
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: 0;
}
#slides .slide {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
}
#slides .slide .title {
  position: absolute;
  top: calc(75% - 0.5em);
  left: 20px;
  z-index: 2;
  padding-top: 5px;
  font-family: "Reem Kufi", sans-serif;
  font-size: 5em;
  color: white;
  overflow: hidden;
  display: none;
}
#slides .slide .title .title-text {
  display: block;
  transform: translateY(1.2em);
  transition: transform 1s ease-in-out;
}
#slides .slide .slide-partial {
  position: absolute;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 1s ease-in-out;
}
#slides .slide .slide-partial img {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transition: transform 1s ease-in-out;
}
#slides .slide .slide-left {
  top: 0;
  left: 0;
  transform: translateX(-100%);
}
#slides .slide .slide-left img {
  top: 0;
  right: 0;
  -o-object-position: 100% 50%;
     object-position: 100% 50%;
  transform: translateX(50%);
}
#slides .slide .slide-right {
  top: 0;
  right: 0;
  transform: translateX(100%);
  transition-delay: 0.2s;
}
#slides .slide .slide-right img {
  top: 0;
  left: 0;
  -o-object-position: 0% 50%;
     object-position: 0% 50%;
  transition-delay: 0.2s;
  transform: translateX(-50%);
}
#slides .slide.active .title .title-text {
  transform: translate(0);
  transition-delay: 0.3s;
}
#slides .slide.active .slide-partial, #slides .slide.active .slide-partial img {
  transform: translateX(0);
}

#slide-select {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: "Reem Kufi", sans-serif;
  font-size: 1.5em;
  font-weight: lighter;
  color: white;
}
#slide-select li {
  position: relative;
  cursor: pointer;
  margin: 0 5px;
  color: #fff;
}
#slide-select li.prev:hover {
  transform: translateX(-2px);
}
#slide-select li.next:hover {
  transform: translateX(2px);
}
#slide-select .selector {
  height: 14px;
  width: 14px;
  border: 2px solid white;
  background-color: transparent;
  transition: background-color 0.5s ease-in-out;
}
#slide-select .selector.current {
  background-color: white;
}

.detailbox {
    border: 3px solid var(--maroon);
    border-radius: 3px;
    background: #fff;
    position: relative;
}

.detailboximg {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 120px;
    margin: -80px auto 0 auto;
    padding: 7px;
    position: relative;
    border-radius: 100px;
}
.detailboximg:after {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    border: 1px dashed var(--maroon);
    border-radius: 100px;
    animation: rotate  10s infinite linear;
}

@keyframes rotate{
  0%{
transform: rotate(0deg);
  } 
    100%{
transform: rotate(360deg);
  } 
} 
.detailsbtn {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 30px;
}
.detailboxdesc {
    padding: 30px;
}

.detailssec {
    margin-top: 180px;
    width: 100%;
    padding: 0px 10px 100px 10px;
    background: linear-gradient(185deg, #cba55391, #cba55391),url('https://webapi.entab.info/api/image/LFIS/public/Images/detailssecbg.jpg') no-repeat;
    background-size: cover;
}
.details_sec {
    margin-top: -50px;
}
.detailboxdesc h5 {
    text-align: center;
    color: var(--maroon);
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
}

.detailboxdesc p {
    font-size: 18px;
    text-align: center;
    line-height: 30px;
}
.homeaboutimg {
    position: relative;
}
.homeaboutbtn {
    margin-top: 50px;
    margin-bottom: 20px;
}
.aboutimgshape {
    position: absolute;
    top: -10px;
    right: -28px;
    z-index: -1;
    animation: itupdown .8s infinite alternate;
    width: 30%;
}
@keyframes itupdown{
    
    0% {
    transform: translateY(0);
}
100% {
    transform: translateY(-20px);
}
}
.homeaboutimg:before {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    top: -15px;
    left: -15px;
    background: url('https://webapi.entab.info/api/image/LFIS/public/Images/about-1.png') no-repeat;
    z-index: -1;
}
.homeaboutimg:after {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    bottom: -15px;
    background: url('https://webapi.entab.info/api/image/LFIS/public/Images/about-2.png') no-repeat bottom right;
    z-index: -1;
    right: -20px;
}
.homeabout h5.mainheading + p {
    text-align: center;
    font-size: 20px;
    color: var(--maroon);
    margin-bottom: 50px;
    margin-top: 35px;
}

.homeabout p {
    font-size: 18px;
    line-height: 33px;
    color: var(--black);
}
.homeabout .mainheading:before {
} .campus-logo { position: fixed; left: 0; top: 50%; z-index: 99; }
 .campus-logo img { width: 100px; height: 100px; }

 button.navbar-toggler {
    background: var(--maroon);
    right: 10px;
    position: absolute;
    top: -60px;
}
button.navbar-toggler i {
    color: var(--white);
    position: relative;
    top: 5px;
}

.innerslide {
    background: url('https://webapi.entab.info/api/image/LFIS/public/Images/sld.jpg')  no-repeat center bottom;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
    flex-direction: column;
    position: relative;
    width: 100%;
    background-size: cover;
}

.innerslide h3 {
    color: #fff;
    text-transform: capitalize;
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 15px;
}

ul.braedcrumb {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;
}

ul.braedcrumb li {
    padding: 0px 10px;
    position: relative;
    color: #ffffff94;
    font-size: 13px;
}

ul.braedcrumb li:before {
    position: absolute;
    content: '|';
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: #ffffff94;
    left: 0;
}
ul.braedcrumb li a {
    color: #cba557;
    position: relative;
    z-index: 1;
}
ul.braedcrumb li:first-child:before {
    content: ' ';
}
.innersec {
    min-height: 500px;
    padding: 60px 0px;
}
.innersec p {
    font-size: 16px;
    line-height: 36px;
    color: var(--black);
    font-family: 'Poppins', sans-serif;
}
.msgimg {
    position: relative;
    margin-right: 25px;
    margin-bottom: 30px;
}

.msgimg:before {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    top: 40px;
    left: 50px;
    background: var(--white);
    z-index: -1;
    box-shadow: 0 30px 50px rgb(8 13 62 / 15%);
}
span.wel-name {
    display: block;
    font-size: 16px;
    color: var(--yellow);
    font-weight: bold;
}
.admisionimg {
    float: right;
    width: 40%;
    margin-left: 30px;
    margin-bottom: 30px;
    position: relative;
}

.admisionimg:before {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    top: 20px;
    left: 20px;
    border: 2px solid #7a2e30;
    z-index: -1;
    border-radius: 6px;
}

.admisionimg img {
    border-radius: 6px;
    width: 100%;
}
.quotes {
    background: #ffffff;
    padding: 22px;
    width: 58%;
    border-radius: 5px;
    margin-bottom: 30px;
    box-shadow: inset 0px 0px 17px 19px #be973e17;
    /* border: 1px dashed #bb5757; */
    /* outline: 1px solid #be973e; */
    /* outline-offset: 7px; */
    /* margin-top: 30px; */
}

.quotes p {
    color: #423939;
}
.leftquo {
    float: right;
}
.admisiontable thead {
    background: var(--yellow);
    color: #fff;
}

.admisiontable tbody tr:nth-child(odd) {
    background: #f1f1f1;
}

.admisiontable thead th {
    border: 1px solid #fff;
}

.admisiontable tbody td {
    border: 1px solid #ccc;
}
ul.admisionli {
    display: flex;
    padding-left: 0;
    flex-wrap: wrap;
    margin-bottom: 25px;
    margin-top: 25px;
}

ul.admisionli li {
    width: 47%;
    font-size: 16px;
    line-height: 34px;
    color: var(--black);
    position: relative;
    margin: 0px 10px;
    padding-left: 25px;
    font-family: 'Poppins', sans-serif;
}
ul.admisionli li:before {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0px;
    content: '\F26A';
    font-family: bootstrap-icons!important;
    color: #702426;
}
ul.admisionli li:nth-child(1):before {
    /* color: #2896af; */
}

ul.admisionli li:nth-child(2):before {
    /* color: #d1a82a; */
}

ul.admisionli li:nth-child(3):before {
    /* color: #972b34; */
}

ul.admisionli li:nth-child(4):before {
    /* color: #388360; */
}
.mid-head {
    color: #722628;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    font-family: 'Roboto',sans-serif;
}
.admisiontable {
    margin-bottom: 10px;
}
.grdxt {
    font-size: 24px;
    text-transform: capitalize;
    font-weight: bold;
    font-family: 'Roboto',sans-serif;
    color: #772b2d;
    position: relative;
    margin-bottom: 10px;
    padding: 6px 0px 8px 15px;
}
.grdxt:before {
    position: absolute;
    top: 0;
    left: 0;
    content: ' ';
    width: 7px;
    background: -webkit-linear-gradient(#cba553, #722628);
    height: 100%;
}
.info {
    background: #f1f1f1a8;
    padding: 30px;
    margin-top: 20px;
    border-radius: 4px;
    border: 1px dashed #ccc;
}

.innersec p b {
    color: #000000bf;
}
.navbar .nav-item:hover .dropdown-menu li {
    padding: 0;
}

.navbar .nav-item .dropdown-menu li a {
    padding: 10px 15px;
    border-bottom: 1px solid #c7a65a;
    color: #fff;
    display: block;
}
.navbar .nav-item .dropdown-menu {
    background: var(--maroon);
    min-width: max-content;
    border-radius: 3px;
}

.navbar .nav-item .dropdown-menu li a:hover {
    background: var(--yellow);
}
.navbar .nav-item .dropdown-menu {
    border: 0px solid #ccc;
}
p.smallhead {
    color: #000;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 30px;
    font-weight: bold;
}
ul.learningli {
    /* display: flex; */
    padding-left: 6px;
    /* flex-wrap: wrap; */
    margin-bottom: 25px;
    margin-top: 25px;
}

ul.learningli li {
    font-size: 16px;
    line-height: 40px;
    color: var(--black);
    position: relative;
    margin: 0px 10px;
    padding-left: 0;
    font-family: 'Poppins', sans-serif;
    list-style: disc;
}
ul.learningli li::marker {
    color: var(--maroon);
}
.innersec b span {
    color: var(--yellow);
}
.facilitiesboximg {
    float: right;
    width: 35%;
    margin-left: 30px;
    margin-bottom: 30px;
    position: relative;
}
.facilitiesboximgright {
    float: left;
    width: 35%;
    margin-right: 30px;
    margin-bottom: 30px;
    position:relative;
}
.facilitiesboximgright:before {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    outline: 3px solid #fff;
    outline-offset: -10px;
    border-radius: 4px;
}
.facilitiesbox.facilitiesboxleft {
    clear: both;
    margin-bottom: 50px;
    border-bottom: 1px dashed #ccc;
}

.facilitiesbox.facilitiesboxright {
    clear: both;
    margin-bottom: 50px;
    border-bottom: 1px dashed #ccc;
}
.clr {
    clear: both;
}
.facilitiesboximg:before {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 100%;
    outline: 3px solid #fff;
    outline-offset: -10px;
    border-radius: 4px;
}
.facilities ul.learningli {
    margin-top: 0;
}
.facilitiesbox.facilitiesboxright ul.learningli {
    position: relative;
    left: 20px;
}
.formbox {
    flex-wrap: wrap;
    background: #fff;
    box-shadow: 0 30px 50px rgb(0 0 0 / 10%);
    display: flex;
    margin-bottom: 60px;
    border-radius: 4px;
}
.formboxright {
    width: 70%;
    padding: 50px 20px 50px 20px;
}

.formboxleft {
    width: 30%;
    background: url('https://webapi.entab.info/api/image/LFIS/public/Images/form-bg.png') no-repeat center bottom;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-size: cover;
}
.addressimg {
    float: left;
    width: 45px;
    height: 45px;
    background: #fff;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 10px;
}

.addressdesc a {
    color: #fff;
    font-size: 14px;
    line-height: 33px;
    font-family: 'Poppins', sans-serif;
    word-break: break-all;
}

.addressdesc {
    margin-bottom: 20px;
    clear: both;
}
.form-group {
    margin-bottom: 15px;
}

.form-group label {
    margin-bottom: 10px;
}
.formboxleft h5 {
    font-size: 30px;
    color: #fff;
    font-family: revert;
    margin-bottom: 30px;
    text-align: center;
    line-height: 40px;
}
input[type="submit"] {
    transition: all 0.3s ease-in;
    padding: 10px 30px;
    border: 0px solid #ccc;
    background: linear-gradient(45deg, #5d1010, #be973e);
    color: #fff;
    border-radius: 4px;
    margin-top: 10px;
}
input[type="submit"]:hover {
    background: var(--maroon);
}
.addressimg i {color: var(--maroon);}
.laboratoriesimg {
    border: 10px solid #fff;
    box-shadow: 0px 0px 13px 3px #cccccc6e;
    margin-bottom: 30px;
}
.div {
    display: none;
}
.selectedyear {
    display: block;
}
.videodetails span {
    font-size: 14px;
}

.videodesc h3 {
    font-size: 18px;
    color: #333;
    font-family: 'Poppins', sans-serif;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.videodetails a i {
    font-size: 40px;
    color: #ff0000;
    margin-right: 20px;
}

.videodetails a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #333;
}

.videocontainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.videodetails {
    width: 48%;
    background: #fff;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0px -2px 6px 0px #0000004f;
}

.videodesc {
    width: 93%;
}

select#yearselect {
    float: right;
    margin-bottom: 20px;
    background: linear-gradient(45deg, #5d1010, #be973e);
    padding: 10px 20px;
    color: #fff;
    font-size: 17px;
    border-radius: 4px;
    border: 0px;
}
select#yearselect option {
    color: #000;
}

.magnify-modal {
    position: fixed !important;
    z-index: 999999999 !important;
    padding-top: 10px !important;
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    height: 100% !important;
    overflow: auto !important;
    background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
    float: right;
    position: relative;
    z-index: 9
}

.magnify-stage {
    position: absolute;
    top: 40px;
    right: 10px;
    bottom: 40px;
    left: 10px;
    z-index: 1;
    background: #0000000a;
    overflow: hidden
}

.magnify-image {
    position: relative;
    display: inline-block;
    border: 5px solid #fff
}

.magnify-image.image-ready {
    max-width: 100%;
    max-height: 100%
}

.magnify-footer {
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 40px;
    color: #fff;
    text-align: center
}

.magnify-footer .magnify-toolbar {
    display: inline-block
}

.campus-logo {
    position: fixed;
    left: 0;
    top: 70%;
    z-index: 99;
}

.campus-logo img {
    width: 80px;
    height: 80px;
}

.magnify-button {
    display: inline-block;
    width: 40px;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    border-width: 0;
    border-radius: 0;
    cursor: pointer;
    outline: none;
    background: #055a91;
}

.magnify-button:hover {
    color: #fff;
    background-color: #e00000
}

.magnify-button-close:hover {
    background-color: #e00000
}

.magnify-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);
    color: #333
}

.magnify-loader::before {
    content: '';
    display: inline-block;
    position: relative;
    width: 36px;
    height: 36px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    -webkit-animation: magnifyLoading 1s infinite linear;
    animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    overflow: hidden
}

@-webkit-keyframes magnifyLoading {
    0% {
        -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
        transform: rotateZ(0deg) translate3d(0, 0, 0)
    }

    100% {
        -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
        transform: rotateZ(360deg) translate3d(0, 0, 0)
    }
}

@keyframes magnifyLoading {
    0% {
        -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
        transform: rotateZ(0deg) translate3d(0, 0, 0)
    }

    100% {
        -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
        transform: rotateZ(360deg) translate3d(0, 0, 0)
    }
}

.backDiv {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    filter: blur(3px);
    background-size: 100% 100% !important;
}

.row.tabs-dynamic {
    background: #6f23251a;
    padding: 10px;
    margin: 0px 0px 40px 0px;
}

.row.tabs-dynamic p {
    margin-bottom: 0;
}

.row.tabs-dynamic select {
    width: 100%;
    border: 0px;
    background: transparent;
    padding: 8px 5px;
    border-bottom: 1px solid #b6b6b6;
}

.row.tabs-dynamic input {
    padding: 9px 5px;
    width: 100%;
    border: 0px;
    border-bottom: 1px solid #b6b6b6;
    background: transparent;
}

.count-val p {
    margin-bottom: 0px;
}

.gallery-inner {
    background: #722628;
    margin-bottom: 30px;
    box-shadow: 7px 6px 12px -4px #ddd;
    /* position: relative; */
    overflow: hidden;
    transition: all 0.5s ease-in;
}
.gallery-blk a {
    color: inherit;
}
.gallery-content {
    padding: 20px 15px;
    position: relative;
    background: #fff;
    width: 96%;
    margin: -30px auto 10px auto;
}

.gallery-blk img {
    width: auto;
    max-width: 100%;
    height: 90%;
    margin: auto;
    display: block;
    position: relative;
    box-shadow: 0px 0px 19px 0px #00000054;
    border: 7px solid #ffffff4f;
}

.gallery-inner:hover .gallery-content {
    bottom: 0;
}
.gallery-blk {
    height: 230px;
    object-fit: contain;
    position: relative;
    background: #ddd;
    overflow: hidden;
    transition: 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
}
.sub-gallerycount .gallery-blk img {
    height: 100%;
}
.sub-gallerycount .gallery-blk {
    height: 200px;
    margin-bottom: 20px;
}
.gallery-blk .gallery-title h3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.gallery-title h3 {
    font-size: 18px;
    color: #d19f09;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    padding: 0px;
    margin-bottom: 15px;
    font-family: 'Poppins', sans-serif;
}
a.view-more {
    color: var(--black);
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
}
.gallery-title p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    line-height: 28px !important;
    font-size: 16px !important;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
}
.gallery-date {
    display: flex;
}

.gallery-date h6 {
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 25px;
    background: #fc097c;
    padding: 5px 10px;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
}
.gall-num h5 {
    background: transparent;
    color: #000;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 25px;
    padding: 5px 10px;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
}
.years {
    display: none;
}

.showYear {
    display: flex;
}

.load-more .next {
    background: var(--yellow);
    width: max-content;
    padding: 5px 10px;
    color: #fff;
    border-radius: 5px;
    margin: auto;
    margin-top: 20px;
}
.gallery-title {
    font-size: 20px;
    line-height: 30px;
    font-family: 'Poppins', sans-serif;
}
.galleryCount:nth-child(2n+2) .gallery-inner .gallery-date h6 {
    background: #f9d464;
}
.galleryCount:nth-child(2n+3) .gallery-inner .gallery-date h6 {
    background: #00acee;
}
.galleryCount:nth-child(2n+4) .gallery-inner .gallery-date h6 {
    background: #198754;
}
.newsbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #fff;
    box-shadow: 2px 0px 8px 0px #ccc;
    margin-bottom: 20px;
}

.newsboximg {
    width: 40%;
    margin-right: 10px;
}

.newsboxdesc {
    width: 58%;
}
.newspgh {
    height: 150px;
    overflow-y: scroll;
    padding-top: 15px;
    margin-top: 10px;
}

.newsboximg img {
    border: 10px solid #fff;
    height: 190px;
    object-fit: contain;
    background: #f1f1f1;
    width: 100%;
}
.newsdate {
    display: flex;
    justify-content: space-between;
    padding: 8px 5px;
}
.newspgh p {
    font-size: 15px;
    line-height: 30px;
}
.newsdate a {
    background: var(--yellow);
    border-radius: 5px 3px 6px 0px;
    color: #fff;
    padding: 2px 5px;
}
.newsdate span {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 30px;
    color: var(--maroon);
}
.newspgh h5 {
    font-family: 'Roboto',sans-serif;
    color: #000000ad;
    line-height: 30px;
    font-size: 18px;
}
.newspgh::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.newspgh::-webkit-scrollbar-thumb {
    background-color: var(--maroon);
}
.navbar .nav-item .dropdown-menu li ,.navbar .nav-item .dropdown-menu li:last-child a {
    border-bottom: 0px solid #fff;
}
button.navbar-toggler:focus {
    box-shadow: none;
}
.row.tabs-dynamic p a {
    color: #333;
}
.erp_sec .guideheading {
    text-align: left;
}

.erp_sec h4 {
    text-align: center;
    line-height: 40px;
    font-size: 17px;
    margin-bottom: 0;
    margin-top: 15px;
    font-weight: 700;
    font-family: 'Roboto',sans-serif;
}

.assistance {
    background: rgb(229 21 32 / 8%);
    padding: 13px;
    border: solid 1px #ddd;
    margin-top: 20px;
}
.erpbox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.erpboxdesc {
    width: 72%;
}

.erpboximg {
    width: 26%;
}

.erpboxdescpopup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.erppopup {
    width: 30%;
    margin: 10px;
}

.imgheading a {
    color: #20488a;
    text-align: center;
    width: 100%;
    display: block;
    font-size: 20px;
    padding: 10px 0;
}

.erppopup .modal-body h5 {
    color: #000;
    text-align: center;
    padding-top: 20px;
}

#myImg,
#myImg1,
#myImg2 {
    width: 100%;
    border: 5px solid rgb(191 185 188);
    background: #cbcaca;
    cursor: pointer;
    transition: .3s;
}
.modal {
    background: #0000008a;
    z-index: 99999;
    padding-right: 0 !important;
}
.details a {
    color: inherit;
}
.navbar .dropdown-item.active, .navbar .dropdown-item:active {
    background: var(--yellow);
}
.form-group input.form-control:focus , .form-group textarea.form-control:focus {
    box-shadow: none;
    border: 1px solid #ccc;
}

.form-group textarea {
    resize: none;
}
input#myInput:focus {
    outline: 0px;
}
.info a {
    color: var(--yellow);
    word-break: break-all;
}
.homeaboutinfo div {
    font-size: 18px;
    line-height: 33px;
    color: var(--black);
    font-family: var(--font-family);
}

.homeaboutinfo {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.owl-theme .owl-dots .owl-dot span {
    width: 10px;
    height: 10px;
    margin: 5px 7px;
    background: #D6D6D6;
    display: block;
    -webkit-backface-visibility: visible;
    transition: opacity .2s ease;
    border-radius: 30px;
}


.homenews .owl-theme .owl-dots .owl-dot.active span, .homenews .owl-theme .owl-dots .owl-dot:hover span, .skillingsec .owl-theme .owl-dots .owl-dot.active span, .skillingsec .owl-theme .owl-dots .owl-dot:hover span {
    background: var(--yellow);
    width: 40px;
}
span.navbar-toggler-icon {
    background-image: none;
}
.primary-alert p{
    background: #e0ffe0;
    color: green;
    padding: 7px;
    text-align: center;
}
.appmobile img {
    width: 100px;

    margin-right: 10px;
}

.appmobile {
    display: flex;
}
.appmobile p {
    margin-right: 40px;
    margin-bottom: 0;
    margin-top: 20px;
}
@media all and (min-width: 992px) {

   .navbar .nav-item .dropdown-menu {
        display: none;
    }
   .navbar .nav-item:hover .dropdown-menu {
        display: block;
        margin: 0;
        padding: 0;
    }

   .navbar .nav-item .dropdown-menu {
        margin-top: 0;
    }
}  


 @media (max-width: 1600px)  
 {
     .nav-link {
    font-size: 16px;
    line-height: 30px;
     }
    .navbar-nav li {
    padding: 10px 1px;
}
.header-left img {
    width: 300px;
}
.header-right-bottom nav ,.header-right-top ul{
    padding-right: 0;
}
.counters p {
    margin: 0px 5px 0px 5px;
    padding: 0px 5px;
    }
}
 @media (max-width: 1400px)  
 {
     .formboxright {
    width: 60%;
     }
     .formboxleft {
    width: 40%;
    }
.skillingcircleboximg {
    width: 120px;
    height: 120px;
    }
    .header-left img {
    width: 265px;
}
.nav-link {
    font-size: 15px;
    line-height: 30px;
    }
    .navbar-nav li {
    padding: 10px 0px;
}
    .header {
    padding: 0px 20px


}
}
 @media (max-width: 1200px) 
 {
     .newsboxdesc {
    width: 57%;
}
     .erppopup {
        width: 45%;
    }
     .formboxleft {
    width: 50%;
}
.formboxright {
    width: 50%;
}
     .facilitiesboximgright {
    width: 45%;
     }
     .facilitiesboximg {
    width: 45%;
     }
     .quotes {
    width: 100%;
    margin-top: 50px;
}
     .admisionimg {
    width: 47%;
     } 
.header {
    flex-wrap: wrap;
}
.header-right-top {
    margin-top: 20px;
    justify-content: center;
    }
.header-right-bottom {
    justify-content: center;
    }
    .skillingcirclebox {
    width: calc(25% - 30px );
}
.counters h4,.counters span {
    font-size: 55px;
    line-height: 65px;
    }
    .counters .container-fluid, .skillingsec .container-fluid, .gallery .container-fluid {
    padding: 0px 50px;
}
.counters:before ,.counters:after{
    width: 50px;
    }
    .counters p {
    font-size: 16px;
}

}
 @media (max-width: 992px) 
 {
     .homeabout h5.mainheading + p {
    margin-bottom: 20px;
     }
     .footerbottom:before {
    left: 40px;
    top:10px;
    bottom:0px;
     }
        .footerbottom:before {
    background: url('https://webapi.entab.info/api/image/LFIS/public/Images/footerlogo.png') no-repeat left top;
     }
     .footerbottom {
    background-size: cover;
     }

     .footerbottom {
    padding: 260px 0px 0px 0px;
    }
     .footerbottom h4 {
    margin-top: 15px;
    margin-bottom: 15px;
}
.navbar .nav-item .dropdown-menu {
    padding: 4px;
    margin-bottom: 10px;
}
     a.nav-link.active {
    color: var(--maroon);
}
     .formboxleft {
    width: 100%;
}
.formboxright {
    width: 100%;
}
      .facilitiesboximgright {
    width: 50%;
     }
     .facilitiesboximg {
    width: 50%;
     }
     ul.admisionli li {
    width: 100%;
     }
     .admisionimg {
    width: 55%;
}
    .navbar .nav-item:hover .nav-link, .navbar .nav-link:focus {
    color: var(--maroon) !important;
}
    .header-left {
    display: flex;
        justify-content: flex-start;
    width: 100%;
}
    .navbar-nav{background: var(--yellow);}
    .navbar-nav li {
    padding: 0px 10px;
        border-bottom: 1px solid #fff;
}
.navbar .nav-item .dropdown-menu li {
    padding: 0px;
}
.nav-link {
    color: var(--white);
}
.header {
    padding: 0px 0px;
}
    .navbar {
    width: 100%;
}
.header-right-top, .header-right-bottom .mainbtn {
    display: none;
}
  header .logo { width: 290px; }
 .popup .modal-dialog {width: 94%;min-width: 97%;margin: 0 auto;}
 .midsecbox {
    width: calc(32% - 20px);
}
.countersbox {
    padding: 50px;
}
.skillingcirclebox {
    width: calc(34% - 30px );
}
.detailboxdesc {
    padding: 30px 10px;
}
.detailbox {
    min-height: 250px;
    }
    #homeslider {
    height: 55vh;
}
.homeaboutimg {
    margin-top: 35px;
}
.appmobile p { 
    width: 100%;
}
}
 @media (max-width: 768px) 
 {
    .appmobile p {
        margin-top: 10px;
        margin-bottom: 0;
    }
    .appmobile { 
        flex-wrap: wrap;
    }
  .homenews, .counters, .skillingsec, .homeabout {
    padding: 30px 0px 60px 0px;
}
.gallery
{
      padding: 30px 0px 60px 0px;
}
     .addressdesc {
    margin-bottom: 10px;
    }
     .erpboximg {
        width: 100%;
    }

    .erpboxdesc {
        width: 100%;
    }
     .videodetails {
    width: 100%;
}
     .facilitiesbox.facilitiesboxright ul.learningli {

    left: 0;
}
     .facilitiesboximgright {
    width: 96%;
    margin-right: 0;
}
     .facilitiesboximg {
    width: 97%;
    float: none;
    margin-left: 0;
}
     .innerslide h3 {
    font-size: 30px;
    line-height: 40px;
     }
     .admisionimg {
    width: 100%;
}
     .msgimg {
    margin-bottom: 70px;
}
    .excellencediv p {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 20px;
}
    .mainheading {
    font-size: 35px;
}
    .detailbox {
    min-height: auto;
}
    .countersbox {
    padding: 20px 40px;
}
.details_sec {
    margin-top: 60px;
    margin-bottom: 60px;
}
.detailssec {
    margin-top: 50px;
    padding: 100px 10px 0px 10px;
}
.midsecbox {
    width: calc(33% - 20px);
    padding: 10px;
}
}
 @media (max-width: 576px) 
 {
     .copyright p {
    font-size: 14px !important;
    line-height: 24px !important;
     }
     .newsdate {
    padding: 8px 10px;
}
     .quotes {
    margin-top: 20px;
}
     .info {
    padding: 30px 10px;
     }
     .innersec {
    padding: 30px 0px;
    min-height: 400px;
}
     .galleryimgbox {
    height: auto;
    min-height: auto;
}
      .erppopup {
        width: 100%;
    }
     .newspgh {
    padding-top: 15px;
    padding-left: 14px;
    margin-top: 0;
}
     .newsboxdesc {
    width: 100%;
}
     .newsboximg {
    width: 100%;
    margin-right: 0;
    }
     .sub-gallerycount .gallery-blk {
    height: 99%;
    margin-bottom: 20px;
}
     .admisionimg {
    width: 94%;
    float: none;
    margin-left: 0;
}
     .homeaboutimg {
    width: 90%;
    margin:35px auto 0px auto;
}
    .mainheading {
    font-size: 26px;
    line-height: 36px;
}
    #homeslider {
    height: 30vh;
}
    .counters .container-fluid, .skillingsec .container-fluid, .gallery .container-fluid {
    padding: 0px 20px;
}
.skillingcirclebox {
    width: 42%;
}
.midsecbox {
    width: calc(50% - 20px);
    padding: 10px;
}
.midsection {
    display: none;
    }
.midsectionbottom {
    margin-top: 0;
    background: linear-gradient(185deg, #5d1010a1, #5d1010a1),url('https://webapi.entab.info/api/image/LFIS/public/Images/midsection.jpg') no-repeat;
    background-size: cover;
    padding: 30px 0px;
}
}
 @media (max-width: 420px) 
 {
     .addressdesc {
    margin-bottom: 15px;
}
     .addressimg {
    margin-right: 10px;
     }
     .addressdesc a {
    line-height: 27px;
     }
     .infrastructuredesc {
    padding: 10px 0px;
}
     .homenewsboximg img {
    height: 165px;
     }
     .header-left img {
    width: 240px;
}
    .skillingcircleboximg {
    width: 90px;
    height: 90px;
}
.skillingcircleboximg img {
    padding: 10px;
}
.countersbox {
    padding: 20px;
}
.counters:before, .counters:after {
    width: 10px;
}
.counters .row> [class*="col-"] {
    border-right: 0px dashed var(--black);
}
.formboxleft {
    padding: 50px 10px;
}
.midsec>div:last-child {
    width: 100%;
}
}



